$nav-function-top-item-padding: 8px;
$nav-function-top-logo-width-sm: 28px;
$nav-function-top-logo-width-lg: 28px;
$nav-function-top-menu-item-bg: $primary-500;

.nav-padel-left,
.nav-padel-right {
  display: none;
}

@include media-breakpoint-up($mobile-breakpoint) {
  .nav-function-top {
    /* digitally created elements */
    .nav-menu-wrapper {
      flex: 0 1 100%;
    }

    /* hide elements when nav-function-top */
    .hidden-nav-function-top {
      display: none !important;
    }

    /* correct search field color */
    #search-field {
      color: $white;
    }

    &:not(.header-function-fixed) {
      #nff {
        position: relative;

        .onoffswitch-title {
          color: $settings-incompat-title;
        }
        .onoffswitch-title-desc {
          color: $settings-incompat-desc;
        }
        &:after {
          content: "DISABLED";
          display: block;
          position: absolute;
          background: $settings-incompat-bg;
          font-size: 10px;
          width: 65px;
          text-align: center;
          border: 1px solid $settings-incompat-border;
          height: 22px;
          line-height: 20px;
          border-radius: $border-radius-plus;
          right: 13px;
          top: 26%;
          color: $fusion-900;
        }
      }
    }

    .page-header {
      margin-top: 0;
      height: $header-height-nav-top;

      background-image: -webkit-linear-gradient(270deg, $nav-background-shade, transparent);
      background-image: linear-gradient(270deg, $nav-background-shade, transparent);
      background-color: $nav-background;

      position: absolute;
      top: 0;
      right: 0;
      left: 0;

      box-shadow: 0px 0px 14px 0px $header-border-bottom-color;

      .dropdown-icon-menu {
        display: none;
      }

      #search-field {
        margin: 0 !important;
      }

      .page-logo {
        display: flex;
        width: auto;
        width: initial; //for IE, cause you know, they suck...
        padding-left: 0;
        background: transparent;
        box-shadow: none;
      }

      .header-icon:not(.btn) > [class*="fa-"]:first-child,
      .header-icon:not(.btn) > .ni:first-child {
        color: lighten($header-link-color, 10%);

        &:hover {
          color: lighten($header-link-color, 20%);
        }
      }

      .badge.badge-icon {
        box-shadow: 0 0 0 1px $primary-600;
      }

      .header-icon:not(.btn)[data-toggle="dropdown"] + .dropdown-menu {
        top: $header-height-nav-top !important;
      }
    }

    .page-content-wrapper {
      margin-top: $nav-top-height + $header-height-nav-top;
    }

    .page-wrapper {
      padding-left: 0;

      .page-footer {
        width: 100%;
      }
    }

    .page-sidebar {
      display: flex;
      width: 100%;
      max-width: 100%;
      height: $nav-top-height;
      z-index: $depth-header - 1;
      padding: 0 0.625rem;
      background: $white;

      box-shadow: 0px 0px 14px 0px $header-border-bottom-color;

      position: absolute;
      top: $header-height-nav-top;

      order: 2;

      .page-logo,
      .nav-filter,
      .info-card,
      .nav-title {
        display: none;
      }

      .primary-nav {
        flex: 1;
        display: flex;
        align-items: stretch;
        font-size: 0;

        /* Make an auto-hiding scroller for the 3 people using a IE */
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /* Remove the default scrollbar for WebKit implementations */

        &::-webkit-scrollbar {
          display: none;
        }

        .nav-menu {
          margin: 0;
          margin-left: 2.9rem; /* this will get overriden with JS script, but we add it here as a counter weight for the flickering effect */
          padding: 0;
          display: flex;
          flex-direction: row;
          align-items: stretch;
          flex: 0 1 100%;

          transition: margin 0.5s ease-out 0s;

          > li {
            display: inline-block;
            position: static; //beacuse initial don't work in fking IE

            &.nav-title {
              display: none;
            }

            &.active {
              > a {
                box-shadow: none;

                &:before {
                  content: "\f413";
                  font-family: "nextgen-icons";
                  position: absolute;
                  top: calc(50% + 15px);
                  right: calc(50% - 5px);
                  font-size: 7px;
                  height: 10px;
                  width: auto;
                  color: #24b3a4;
                }
              }
            }

            > a {
              padding: 0.75rem 1.5rem 0.75rem 1rem;
              text-align: center;

              height: 100%;

              > .#{$cust-icon-prefix},
              > [class*="fa-"] {
                width: inherit;
                margin: 0;
                margin-right: 0.5rem !important;
                display: flex !important;
                align-items: center;
                justify-content: left;
                color: inherit;
              }

              > .nav-link-text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: normal;
                vertical-align: text-top;
                font-weight: 400;

                display: inline-block; /*override inline-flex*/

                // this is needed
                flex: 0 1 auto;
              }

              > .badge,
              > .badge.clear-badge {
                left: 53%;
              }

              > .collapse-sign {
                margin-left: 0.5rem;
                color: lighten($nav-background, 30%);
                position: absolute;
                right: 0.5rem;
                top: 0;
                bottom: 0;

                //force down arrow on all parent
                > em {
                  &:before {
                    content: "\f107";
                  }
                }
              }

              > .badge:first-child {
                max-width: none;
                width: 25px !important;
                height: 25px !important;
                line-height: 16px !important;
                font-size: rem($fs-base) !important;
                display: block !important;
                margin: 0 auto 4px !important;

                // unfortunately we do need '!important' here :(
              }
            }

            // all children
            a {
              font-size: 0.9rem;
              color: $nav-background;
            }

            > ul {
              display: none !important; //force invisibility to override plugin clicks
              width: $nav-top-drowndown-width;
              height: auto !important; //counters the click issue
              top: $nav-top-height;
              position: absolute;
              background: $nav-top-drowndown-background;
              border-radius: $border-radius-plus;
              box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.15);
              padding: 1rem 0;
              margin-top: 1rem;

              li {
                width: 100%;
                position: relative;

                a {
                  padding: 0.65rem 1.25rem;
                  width: 100%;
                  color: $nav-top-drowndown-color;
                  max-height: none;
                  box-shadow: none;

                  .nav-link-text {
                    display: block;

                    > .dl-ref {
                      margin-left: 0;
                      margin-right: 5px;
                      display: none;
                    }
                    > .#{$cust-icon-prefix} > [class*="fa-"] {
                      margin-left: 6px;
                    }
                  }
                }

                ul {
                  background: $nav-top-drowndown-background;
                  padding: 0;

                  li {
                    a {
                      padding-left: 2rem;
                    }
                  }
                }

                &:hover {
                  > a {
                    background: $nav-top-drowndown-hover;
                    color: $nav-top-drowndown-hover-color;
                  }
                }
              }

              &:after {
                content: "";
                display: block;
                width: calc(100% + 100px);
                height: calc(100% + 120px);
                position: absolute;
                z-index: -1;
                left: -50px;
                top: -1rem;
                background: transparent;
              }

              &:before {
                content: "\f1c8";
                font-family: "nextgen-icons";
                position: absolute;
                font-size: 5rem;
                color: $nav-top-drowndown-background;
                overflow: hidden;
                display: block;
                top: -1.7rem;
                left: 0;
              }
            }

            //first child hover
            &:hover {
              > a {
                color: $primary-500;
                background: transparent;

                & + ul {
                  display: block !important;
                  animation: animateFadeInUp 0.5s;
                  -webkit-animation: animateFadeInUp 0.5s;
                }
              }
            }
          }
        }
      }

      .nav-footer {
        display: none;
      }
    }

    &.nav-function-minify {
      .page-sidebar {
        .primary-nav {
          .nav-menu {
            > li {
              > a {
                > .nav-link-text {
                  display: none;
                }

                > .badge {
                  left: 24px;
                }

                > .#{$cust-icon-prefix},
                > [class*="fa-"] {
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    /* reorder */
    .page-header {
      order: 1;
    }

    .page-wrapper {
      display: flex;
      flex-direction: column;
    }

    .page-sidebar {
      order: 2;
    }

    .page-content {
      order: 3;
      align-items: stretch;
      flex: 1 1 auto;
    }
  }
}

@include media-breakpoint-up(xl) {
  .nav-function-top {
    .page-sidebar {
      .primary-nav {
        .nav-menu {
          > li {
            > a {
              > .badge:first-child {
                max-width: none;
                width: 27px !important;
                height: 27px !important;
                line-height: 18px !important;
                margin: 0 auto 2px !important;
              }
              > .#{$cust-icon-prefix},
              > [class*="fa-"],
              > img {
                font-size: 22px;
                height: 22px;
              }
            }
          }

          /*li {
						ul {
							 li {
							 	a {
							 		font-size: rem($fs-base);
							 	}
							 }
						}
					}*/
        }
      }
    }
  }
}
